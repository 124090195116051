import { Step2 } from 'assets';
import {
  Alert,
  Checkbox,
  Container,
  ControlledInput,
  FormSubmit,
  Loading,
} from 'components';
import { Link, navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useAnalytics } from 'modules/analytics/hooks/useAnalytics';
import { OrDivider } from 'modules/authentication';
import { Distributor } from 'modules/distributors';
import { AuthService } from 'modules/firebase';
import { SuccessToast } from 'modules/inbox';
import {
  DistributorsMap,
  haversineDistance,
  MapDistanceOptions,
} from 'modules/map';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { buttons, cart, inputs, map } from 'styles';

import { DistributorState, Position } from '../../modules/cart/models';
import { CartThunks } from '../../modules/cart/redux';
import { selectDistributorsByQuery } from '../../modules/cart/selectors';

const authService = AuthService();

const ChooseDistributor: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const [userPosition, setUserPosition] = useState<Position>({
    lat: 45.798236,
    lng: 15.974698,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [isRequested, setIsRequested] = useState<boolean>(false);
  const [filteredDistributors, setFilteredDistributors] = useState<
    Distributor[]
  >([]);
  const { activeDistributors } = useSelector(
    (state: ApplicationState) => state.distributors,
  );

  useEffect(() => {
    const filteredDistributors = activeDistributors?.filter(
      (dist) => haversineDistance(dist.location, userPosition) < 20,
    );
    if (filteredDistributors) setFilteredDistributors(filteredDistributors);
  }, [userPosition, activeDistributors]);

  const { sendOfferContact, sendOfferInteraction } = useAnalytics();

  const { isLoggedIn, user, isUserAnon } = useSelector(
    (state: ApplicationState) => state.auth,
  );
  const { clientInfo } = useSelector((state: ApplicationState) => state.cart);

  const methods = useForm<DistributorState>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const selectedDistributors = selectDistributorsByQuery({
    filteredDistributors,
    query,
  });

  useEffect(() => {
    if (!isLoggedIn) navigate('/kosarica/prijava');
  }, [isLoggedIn]);

  useEffect(() => {
    setUserPosition({
      lat: clientInfo.location.lat,
      lng: clientInfo.location.lng,
    });
  }, [clientInfo.location]);

  useEffect(() => {
    const filterDistributors = activeDistributors.filter(
      (dist) => haversineDistance(dist.location, userPosition) < 25,
    );
    setFilteredDistributors(filterDistributors);
  }, [userPosition, activeDistributors]);

  const disabledButton = isRequested && buttons.disabled;

  const pageLayout =
    user && !user.emailVerified
      ? cart.step2Page
      : cart.step2PageNoEmailVerificationMessage;

  if (!isLoggedIn) {
    return <Loading isLoading fullPage />;
  }

  return (
    <Container css={pageLayout}>
      {!isLoggedIn && (
        <Alert
          type="warning"
          text={formatMessage({ id: 'cart.data.alert.warning' })}
        />
      )}
      <div>
        <div css={cart.stepTitle}>
          <Step2 />
          <p css={cart.step}>
            <FormattedMessage id="shared.step" values={{ step: 2 }} />
          </p>
        </div>
      </div>
      <div css={cart.distributorHeader}>
        <div css={cart.distributorHeaderWrapper}>
          <h2 css={cart.pageTitle}>
            <FormattedMessage id="cart.choose-distributers.title" />
          </h2>
          <p css={cart.chooseDistributorInfo}>
            <FormattedMessage id="cart.choose-distributers.map-choose" />
          </p>
        </div>

        <Alert
          type="info"
          className={cart.info}
          text={formatMessage({
            id: 'cart.choose-distributers.alert.closest-distributor',
          })}
        />
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div css={cart.distributors}>
            <aside css={cart.distributorsAside}>
              <ControlledInput
                name="distributor-query"
                value={query}
                onChange={setQuery}
                placeholder={formatMessage({
                  id: 'cart.choose-distributor.input.placeholder',
                })}
              />
              <h4 css={cart.subtitle}>
                <FormattedMessage id="cart.choose-distributor.search.title" />
              </h4>

              <div css={cart.distributorList}>
                <ul>
                  {selectedDistributors.length > 0 ? (
                    selectedDistributors.map((dist) => (
                      <li key={dist.id} css={cart.distributorListItem}>
                        <Checkbox
                          name={dist.id}
                          text={dist.name}
                          css={cart.distributorTitle}
                          wrapperClassName={cart.checkboxTitle}
                        />
                        <p css={cart.distributorDescription}>
                          {dist.address}, {dist.postCode} {dist.city}
                        </p>
                      </li>
                    ))
                  ) : (
                    <p css={cart.distributorListItem}>
                      <FormattedMessage id="cart.choose-distributor.search.no-results" />
                    </p>
                  )}
                </ul>
              </div>
            </aside>

            <div css={map.container}>
              <DistributorsMap
                userPosition={userPosition}
                distributors={filteredDistributors}
                handleDistanceChange={handleDistanceChange}
              />

              <div css={cart.ctaContainer}>
                <div css={cart.infoWrapper}>
                  <div css={cart.redLine} />
                  <p css={cart.infoText}>
                    <FormattedMessage id="cart.choose-distributor.info-text" />
                  </p>
                </div>
                {!user?.subscription && (
                  <Checkbox
                    name="subscription"
                    text={
                      <p>
                        <FormattedMessage id="cart.choose-distributor.accept-terms" />
                        <Link to="/pravila-privatnosti">
                          {' '}
                          <FormattedMessage id="shared.privacy-policy" />
                        </Link>
                        .
                      </p>
                    }
                    inputClassName={[inputs.inputCheckbox, cart.checkboxText]}
                  />
                )}
                <Checkbox
                  name="contactConsent"
                  text={formatMessage({
                    id: 'cart.choose-distributor.sms-accept',
                  })}
                  inputClassName={[inputs.inputCheckbox, cart.checkboxText]}
                  defaultChecked={true}
                />
                <div css={cart.buttonWrapper}>
                  <FormSubmit
                    text={formatMessage({
                      id: 'cart.data.request-offer',
                    })}
                    stateIsChanging={isLoading}
                    disabled={!isLoggedIn}
                  />

                  <OrDivider />
                  <Link
                    to="/"
                    css={[
                      buttons.container,
                      buttons.ghost.medium,
                      cart.declineButton,
                      disabledButton,
                    ]}
                  >
                    <FormattedMessage id="cart.data.give-up" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </Container>
  );

  function onSubmit(data: DistributorState) {
    const ids = Object.keys(data).filter(
      (id: string) =>
        data[id] && id !== 'contactConsent' && id != 'subscription',
    );

    if (ids.length === 0) {
      toast.error(
        <SuccessToast
          title={formatMessage({ id: 'cart.submit.error.title' })}
          text={formatMessage({ id: 'cart.submit.no-distributers-error.text' })}
          showSuccessIcon={false}
        />,
      );
      return;
    }
    setIsLoading(true);
    setIsRequested(true);
    const contactEmail = isUserAnon ? clientInfo.email : undefined;
    const offerData = {
      address: clientInfo.address,
      postcode: clientInfo.postCode,
      city: clientInfo.city,
      country: clientInfo.country.value,
      firstName: clientInfo.firstName,
      lastName: clientInfo.lastName,
      email: contactEmail,
      phone: clientInfo.phone,
      company: {
        companyName: clientInfo.business?.name,
      },
    };
    dispatch(
      CartThunks.sendCartData(data, selectedDistributors, user?.isAnonymous),
    ).then(() => {
      setIsLoading(false);
    });

    sendOfferContact({
      ...offerData,
      isSubscribed: data.subscribtion,
    });

    if (data.subscription) {
      if (!user?.subscription && isLoggedIn) {
        authService.subscribeUser();
      }
    }

    const contentData = isUserAnon
      ? 'ANONYMOUS_REQUEST_AN_OFFER'
      : clientInfo.email;

    sendOfferInteraction(contentData);
  }

  function handleDistanceChange(type: MapDistanceOptions) {
    if (type === '20km') {
      const filterDistributors = activeDistributors.filter(
        (dist) => haversineDistance(dist.location, userPosition) < 20,
      );
      setFilteredDistributors(filterDistributors);

      return;
    }

    setFilteredDistributors(activeDistributors);
  }
};

export default ChooseDistributor;
