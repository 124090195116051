import { Distributor } from 'modules/distributors';
import { createCachedSelector } from 're-reselect';
import { applySearchParams } from 'utils';

export const selectDistributorsByQuery = createCachedSelector(
  (state: { filteredDistributors: Distributor[]; query: string }) =>
    state.filteredDistributors,
  (state: { filteredDistributors: Distributor[]; query: string }) =>
    state.query,
  (filteredDistributors, query) =>
    filteredDistributors.filter(({ name, address, city }) =>
      applySearchParams([name, address, city], query),
    ),
)(({ query }) => query);
